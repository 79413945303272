import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import store, { persistor } from "./general/redux/store";
import App from "./general/app/App";
import "./index.scss";
import "./general/template/assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "font-awesome/css/font-awesome.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./general/template/assets/plugins/flaticon/flaticon.css";
import "./general/template/assets/plugins/flaticon2/flaticon.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider
} from "./general/template/layout";

import { createRoot } from "react-dom/client";
import { MetronicI18nProvider } from "./general/template/i18n";
import "bootstrap-icons/font/bootstrap-icons.css";

const { PUBLIC_URL, REACT_APP_PROJECT, REACT_APP_SUBPROJECT } = process.env;

const container = document.getElementById("root");
const root = createRoot(container);

// adding dynamic favicon
const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
link.type = 'image/x-icon';
link.rel = 'icon';
if (REACT_APP_SUBPROJECT) {
  link.href = `/media/project-${REACT_APP_PROJECT}/sub-${REACT_APP_SUBPROJECT}/logos/favicon.ico`;
} else {
  link.href = `/media/project-${REACT_APP_PROJECT}/logos/favicon.ico`;
}
document.getElementsByTagName('head')[0].appendChild(link);

root.render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
        <MetronicSplashScreenProvider>
          <App store={store} persistor={persistor} basename={PUBLIC_URL} />
        </MetronicSplashScreenProvider>
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>
);

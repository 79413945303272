import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { AuthInit } from "./modules/Auth";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../template/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../template/layout";
import { ErrorBoundry } from "./pages/Error/ErrorBoundry";
import { SnackbarProvider } from "../template/layout/components/snackbars/SnackbarProvider";
import HeartbeatInit from "./modules/Heartbeat/_redux/HeartbeatInit";
import { clarity } from "react-microsoft-clarity";

export default function App({ store, persistor, basename }) {
  // Initialize Clarity
  const clarity_id = process.env.REACT_APP_CLARITY_ID;
  if (clarity_id && clarity_id !== "false") {
    clarity.init(clarity_id);
  }

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        <React.Suspense fallback={<LayoutSplashScreen />}>
          <BrowserRouter basename={basename}>
            <MaterialThemeProvider>
              <I18nProvider>
                <SnackbarProvider>
                  <ErrorBoundry>
                    <AuthInit>
                      <HeartbeatInit>
                        <Routes />
                      </HeartbeatInit>
                    </AuthInit>
                  </ErrorBoundry>
                </SnackbarProvider>
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}

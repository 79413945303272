/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from "react";
import { useLocation } from "react-router";
import { NavLink, useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../helpers";
import { menuIcon } from "../../../../partials/reference/DynamicMenuRef";
import _ from "lodash";
import { useLocale } from "../../../../i18n";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const selectedLocale = useLocale();
  const history = useHistory();
  const {
    profile: { menu }
  } = useSelector(state => state.auth, shallowEqual);

  const getMenuItemActive = (url, hasSubmenu = false, menuList = menu) => {
    return checkIsActive(location, url, menuList)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const fetchMenuItem = (menuReference = "", behaviour = "") => {
    if (menuReference !== "" && behaviour === "clickable-expand_collapse") {
      history.push(`/${menuReference}`);
    }
    return false;
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {menu.hasOwnProperty("menu_item_groups")
          ? menu.menu_item_groups.map(value => {
              let str = "";
              if (
                _.find(menu.menu_items, function(o) {
                  return (
                    o.menu_item_group_id === value.id &&
                    o.menu_item_hidden === 0
                  );
                }) !== undefined
              ) {
                str = (
                  <React.Fragment key={value.id}>
                    <li className='menu-section'>
                      <h4
                        id={`aside_menu_heading-${_.find(
                          value.menu_item_group_name,
                          function(o) {
                            return o.locale === selectedLocale;
                          }
                        )?.value ?? value.menu_item_group_name}`}
                        className='menu-text'
                      >
                        {_.find(value.menu_item_group_name, function(o) {
                          return o.locale === selectedLocale;
                        }) !== undefined
                          ? _.find(value.menu_item_group_name, function(o) {
                              return o.locale === selectedLocale;
                            }).value
                          : value.menu_item_group_name}
                      </h4>
                      <i className='menu-icon flaticon-more-v2'></i>
                    </li>
                    {menu.hasOwnProperty("menu_items")
                      ? menu.menu_items
                          .filter(
                            item_value =>
                              item_value.menu_item_hidden === 0 &&
                              item_value.menu_item_group_id === value.id &&
                              item_value.menu_item_parent === "0"
                          )
                          .map(list_value => {
                            const count = menu.menu_items.filter(
                              sub_value =>
                                sub_value.menu_item_hidden === 0 &&
                                sub_value.menu_item_group_id === value.id &&
                                sub_value.menu_item_parent ===
                                  list_value.id.toString()
                            ).length;
                            const menuItemName =
                              _.find(list_value.menu_item_name, function(o) {
                                return o.locale === selectedLocale;
                              })?.value ?? list_value.menu_item_name;
                            if (count > 0) {
                              return (
                                <li
                                  key={list_value.id}
                                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                                    `/${list_value.menu_item_reference}`,
                                    true
                                  )}`}
                                  aria-haspopup='true'
                                  data-menu-toggle='hover'
                                >
                                  <NavLink
                                    className={`menu-link menu-toggle`}
                                    to={`/${list_value.menu_item_reference}`}
                                    onClick={() =>
                                      fetchMenuItem(
                                        list_value.menu_item_reference,
                                        list_value.menu_item_behaviour
                                      )
                                    }
                                    id={`aside_menu-item-${menuItemName}`}
                                  >
                                    <span className='svg-icon menu-icon'>
                                      <SVG
                                        src={toAbsoluteUrl(
                                          `/media/general/svg/icons/General/${
                                            menuIcon[list_value.menu_item_icon]
                                          }`
                                        )}
                                        id={`aside_menu-icon-${menuItemName}`}
                                      />
                                    </span>
                                    <span
                                      className='menu-text'
                                      id={`aside_menu-text-${menuItemName}`}
                                    >
                                      {_.find(
                                        list_value.menu_item_name,
                                        function(o) {
                                          return o.locale === selectedLocale;
                                        }
                                      ) !== undefined
                                        ? _.find(
                                            list_value.menu_item_name,
                                            function(o) {
                                              return (
                                                o.locale === selectedLocale
                                              );
                                            }
                                          ).value
                                        : list_value.menu_item_name}
                                    </span>
                                    <i
                                      className='menu-arrow'
                                      id={`aside_menu-arrow-${menuItemName}`}
                                    />
                                  </NavLink>
                                  <div className='menu-submenu'>
                                    <i className='menu-arrow' />
                                    <ul className='menu-subnav'>
                                      <li
                                        className='menu-item menu-item-parent'
                                        aria-haspopup='true'
                                      >
                                        <span className='menu-link'>
                                          <span className='menu-text'>
                                            {_.find(
                                              list_value.menu_item_name,
                                              function(o) {
                                                return (
                                                  o.locale === selectedLocale
                                                );
                                              }
                                            ) !== undefined
                                              ? _.find(
                                                  list_value.menu_item_name,
                                                  function(o) {
                                                    return (
                                                      o.locale ===
                                                      selectedLocale
                                                    );
                                                  }
                                                ).value
                                              : list_value.menu_item_name}
                                          </span>
                                        </span>
                                      </li>
                                      {menu.menu_items
                                        .filter(
                                          sub_value =>
                                            sub_value.menu_item_hidden === 0 &&
                                            sub_value.menu_item_group_id ===
                                              value.id &&
                                            sub_value.menu_item_parent ===
                                              list_value.id.toString()
                                        )
                                        .map(sub_items_value => {
                                          const subMenuItemName =
                                            _.find(
                                              sub_items_value.menu_item_name,
                                              function(o) {
                                                return (
                                                  o.locale === selectedLocale
                                                );
                                              }
                                            )?.value ??
                                            sub_items_value.menu_item_name;
                                          return (
                                            <li
                                              key={sub_items_value.id}
                                              className={`menu-item ${getMenuItemActive(
                                                `/${sub_items_value.menu_item_reference}`
                                              )}`}
                                              aria-haspopup='true'
                                            >
                                              <NavLink
                                                className='menu-link'
                                                to={`/${sub_items_value.menu_item_reference}`}
                                                id={`aside_submenu-item-${menuItemName}-${subMenuItemName}`}
                                              >
                                                <i id={`aside_submenu-icon-${menuItemName}-${subMenuItemName}`} className='menu-bullet menu-bullet-line'>
                                                  <span />
                                                </i>
                                                <span
                                                  className='menu-text'
                                                  id={`aside_submenu-text-${menuItemName}-${subMenuItemName}`}
                                                >
                                                  {_.find(
                                                    sub_items_value.menu_item_name,
                                                    function(o) {
                                                      return (
                                                        o.locale ===
                                                        selectedLocale
                                                      );
                                                    }
                                                  ) !== undefined
                                                    ? _.find(
                                                        sub_items_value.menu_item_name,
                                                        function(o) {
                                                          return (
                                                            o.locale ===
                                                            selectedLocale
                                                          );
                                                        }
                                                      ).value
                                                    : sub_items_value.menu_item_name}
                                                </span>
                                              </NavLink>
                                            </li>
                                          );
                                        })}
                                    </ul>
                                  </div>
                                </li>
                              );
                            } else {
                              return (
                                <li
                                  key={list_value.id}
                                  className={`menu-item ${getMenuItemActive(
                                    `/${list_value.menu_item_reference}`
                                  )}`}
                                  aria-haspopup='true'
                                >
                                  <NavLink
                                    className='menu-link'
                                    to={`/${list_value.menu_item_reference}`}
                                    id={`aside_menu-item-${menuItemName}`}
                                  >
                                    <span className='svg-icon menu-icon'>
                                      <SVG
                                        src={toAbsoluteUrl(
                                          `/media/general/svg/icons/General/${
                                            menuIcon[list_value.menu_item_icon]
                                          }`
                                        )}
                                        id={`aside_menu-icon-${menuItemName}`}
                                      />
                                    </span>
                                    <span
                                      className='menu-text'
                                      id={`aside_menu-text-${menuItemName}`}
                                    >
                                      {_.find(
                                        list_value.menu_item_name,
                                        function(o) {
                                          return o.locale === selectedLocale;
                                        }
                                      ) !== undefined
                                        ? _.find(
                                            list_value.menu_item_name,
                                            function(o) {
                                              return (
                                                o.locale === selectedLocale
                                              );
                                            }
                                          ).value
                                        : list_value.menu_item_name}
                                    </span>
                                  </NavLink>
                                </li>
                              );
                            }
                          })
                      : ""}
                  </React.Fragment>
                );
              }
              return str;
            })
          : ""}
      </ul>
    </>
  );
}

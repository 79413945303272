import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../template/helpers";
import SVG from "react-inlinesvg";

function SuccessForgotPassword() {
  return (
    <div className='login-form login-signin' id='kt_login_signin_form'>
      <div className='text-center mb-10 mb-lg-20'>
        <SVG
          style={{ width: 125 }}
          src={toAbsoluteUrl(
            "/media/general/svg/icons/General/Success-checkbox.svg"
          )}
        />
        <h3 className='font-size-h1'>
          <FormattedMessage id='SUCCESS' />
        </h3>
        <p className='text-muted font-weight-bold'>
          <FormattedMessage id='AUTH.FORGOT.PASS.SUCCESS' />
        </p>
        <Link
          className={`btn btn-primary font-weight-bold px-9 py-3 my-3`}
          to='/auth/login'
        >
          <FormattedMessage id='BACK.LOGIN' />
        </Link>
      </div>
    </div>
  );
}

export default injectIntl(connect(null)(SuccessForgotPassword));

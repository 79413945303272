import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../helpers";

export function ScrollTop() {
  return (
    <div
      id='kt_scrolltop'
      className={`scrolltop ${
        process.env.REACT_APP_HEPTAGON_FRUITS_PRODUCTCODE
          ? ""
          : "scrolltop--wo-product-fruits"
      }`}
    >
      <span className='svg-icon'>
        <SVG
          src={toAbsoluteUrl("/media/general/svg/icons/Navigation/Up-2.svg")}
          id='kt_scrolltop-svg'
        ></SVG>
      </span>{" "}
    </div>
  );
}

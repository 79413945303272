import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { login } from "../_redux/authCrud";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../template/helpers";
import SVG from "react-inlinesvg";

const {
  actions
} = require(`../../../../../project-${process.env.REACT_APP_PROJECT}/app/modules/Auth/_redux/authRedux`);

const initialValues = {
  email: "",
  password: ""
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: "AUTH.LOGIN.FORMAT"
        })
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    password: Yup.string()
      .min(
        6,
        intl.formatMessage({
          id: "AUTH.VALIDATION.MIN_LENGTH"
        })
      )
      .max(
        50,
        intl.formatMessage({
          id: "AUTH.VALIDATION.MAX_LENGTH"
        })
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      )
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then(res => {
            const { status } = res.data;
            disableLoading();
            if (status) {
              const {
                data: { access_token }
              } = res.data;
              props.login(access_token);
            }
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    }
  });

  return (
    <div id='login_container' className='login-form login-signin'>
      <div className='text-center mb-10'>
        <h3 id='login_container_title' className='font-size-h1'>
          <FormattedMessage id='AUTH.LOGIN.TITLE' />
        </h3>
        <p
          id='login_container_description'
          className='text-muted font-weight-bold'
        >
          <FormattedMessage id='AUTH.LOGIN.DESC' />
        </p>
      </div>

      <form
        onSubmit={formik.handleSubmit}
        className='form fv-plugins-bootstrap fv-plugins-framework'
        id='login_form'
      >
        <div className='form-group fv-plugins-icon-container'>
          <label id='login_form_label-email' className={"font-weight-bold"}>
            {intl.formatMessage({ id: "PLACEHOLDER.EMAIL" })}
          </label>
          <div className='position-relative form-control-iconless'>
            <input
              id='login_form_input-email'
              placeholder={intl.formatMessage({
                id: "PLACEHOLDER.EMAIL"
              })}
              type='email'
              autoComplete='off'
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "email"
              )}`}
              name='email'
              {...formik.getFieldProps("email")}
            />
            {getInputClasses("email") !== "" ? (
              <SVG
                src={toAbsoluteUrl(
                  getInputClasses("email") === "is-valid"
                    ? "/media/general/svg/icons/General/GreenTick.svg"
                    : "/media/general/svg/icons/General/ErrorCircle.svg"
                )}
                className='position-absolute top-50 right-0 translate-Y-50 mr-3'
                id='login_form_input-email-svg'
              />
            ) : (
              <></>
            )}
          </div>
          {formik.touched.email && formik.errors.email ? (
            <div
              id='login_form_errors-email'
              className='fv-plugins-message-container'
            >
              <div className='fv-help-block'>{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className='form-group fv-plugins-icon-container'>
          <div className={"d-flex justify-content-between"}>
            <label
              id='login_form_label-password'
              className={"font-weight-bold"}
            >
              {intl.formatMessage({ id: "PLACEHOLDER.PASS" })}
            </label>
            <Link id='login_form_link-forgot' to='/auth/forgot-password'>
              {intl.formatMessage({ id: "FORGOT.PASS" })}
            </Link>
          </div>
          <div className='position-relative form-control-iconless'>
            <input
              id='login_form_input-password'
              placeholder={intl.formatMessage({
                id: "PLACEHOLDER.PASS"
              })}
              type='password'
              autoComplete='off'
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              name='password'
              {...formik.getFieldProps("password")}
            />
            {getInputClasses("password") !== "" ? (
              <SVG
                src={toAbsoluteUrl(
                  getInputClasses("password") === "is-valid"
                    ? "/media/general/svg/icons/General/GreenTick.svg"
                    : "/media/general/svg/icons/General/ErrorCircle.svg"
                )}
                className='position-absolute top-50 right-0 translate-Y-50 mr-3'
                id='login_form_input-password-svg'
              />
            ) : (
              <></>
            )}
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div
              id='login_form_errors-password'
              className='fv-plugins-message-container'
            >
              <div className='fv-help-block'>{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
          <button
            id='login_form_btn-submit'
            type='submit'
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-3 my-3`}
          >
            <span>
              <FormattedMessage id='AUTH.LOGIN.BUTTON' />
            </span>
            {loading && <span className='ml-3 spinner spinner-white'></span>}
          </button>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, actions)(Login));

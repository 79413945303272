/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../helpers";
import { useLang, setLanguage } from "../../../../i18n";
import { DropdownTopbarItemToggler } from "../../../../partials/dropdowns";
import { FormattedMessage } from "react-intl";

const allowedLanguages = process.env.REACT_APP_ALLOWED_LANGUAGES
  ? process.env.REACT_APP_ALLOWED_LANGUAGES.split(",")
  : ["en"];

const languages = [
  {
    lang: "en",
    name: "English",
    flag: toAbsoluteUrl("/media/general/svg/flags/226-united-states.svg")
  },
  {
    lang: "nl",
    name: "Netherland",
    flag: toAbsoluteUrl("/media/general/svg/flags/237-netherlands.svg")
  }
];

export function LanguageSelectorDropdown() {
  const lang = useLang();
  const currentLanguage = languages.find(x => x.lang === lang);

  if (allowedLanguages.length <= 1) {
    return "";
  }

  return (
    <Dropdown drop='down' align='end'>
      <Dropdown.Toggle as={DropdownTopbarItemToggler}>
        <OverlayTrigger
          placement='bottom'
          overlay={
            <Tooltip id='header_dropdown_tooltip'>
              <FormattedMessage id='HOME.LANG' />
            </Tooltip>
          }
        >
          <div
            className='btn btn-icon btn-clean btn-dropdown btn-lg mr-1'
            id='header_dropdown_toggle-lang'
          >
            <img
              id='header_dropdown_toggle-lang_img'
              className='h-25px w-25px rounded'
              src={currentLanguage.flag}
              alt={currentLanguage.name}
            />
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu className='p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround'>
        <ul className='navi navi-hover py-4'>
          {languages
            .filter(language => allowedLanguages.includes(language.lang))
            .map(language => (
              <li
                key={language.lang}
                className={clsx("navi-item", {
                  active: language.lang === currentLanguage.lang
                })}
              >
                <a
                  href='#'
                  onClick={() => setLanguage(language.lang)}
                  className='navi-link'
                  id={`header_lang-option-${language.lang}`}
                >
                  <span className='symbol symbol-20 mr-3'>
                    <img id={`header_lang-option-${language.lang}_img`} src={language.flag} alt={language.name} />
                  </span>
                  <span id={`header_lang-option-${language.lang}_text`} className='navi-text'>{language.name}</span>
                </a>
              </li>
            ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}

/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../helpers";
import { DropdownTopbarItemToggler } from "../../../../partials/dropdowns";
import { FormattedMessage } from "react-intl";

export function UserProfileDropdown() {
  const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light"
    };
  }, [uiService]);

  return (
    <Dropdown drop='down' align="end">
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
          id='header_dropdown_toggle-user-profile'
        >
          <span id='header_dropdown_toggle-user-profile_hi' className='text-muted font-weight-bold font-size-base d-none d-md-inline mr-1'>
            <FormattedMessage id='Hi' />,
          </span>{" "}
          <span id='header_dropdown_toggle-user-profile_name' className='text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3'>
            {user.user_name}
          </span>
          <span className='symbol symbol-35 symbol-light-success'>
            <span id='header_dropdown_toggle-user-profile_avatar' className='symbol-label font-size-h5 font-weight-bold'>
              {user.user_name[0]}
            </span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className='p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl'>
        <>
          {layoutProps.light && (
            <>
              <div className='d-flex align-items-center p-8 rounded-top'>
                <div className='symbol symbol-md bg-light-primary mr-3 flex-shrink-0'>
                  <img id='header_user_info-img' src={toAbsoluteUrl("/media/general/users/300_21.jpg")} alt='' />
                </div>
                <div id='header_user_info-name' className='text-dark m-0 flex-grow-1 mr-3 font-size-h5'>
                  {user.user_name}
                </div>
              </div>
              <div className='separator separator-solid'></div>
            </>
          )}

          {!layoutProps.light && (
            <div
              className='d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top'
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/general/misc/bg-1.jpg")})`
              }}
            >
              <div className='symbol bg-white-o-15 mr-3'>
                <span id='header_user_info-icon' className='symbol-label text-success font-weight-bold font-size-h4'>
                  {user.user_name[0]}
                </span>
              </div>
              <div id='header_user_info-name' className='text-white m-0 flex-grow-1 mr-3 font-size-h5'>
                {user.user_name}
              </div>
            </div>
          )}
        </>

        <div className='navi navi-spacer-x-0 pt-5'>
          <div className='navi-separator mt-3'></div>

          <div className='navi-footer  px-8 py-5'>
            <Link
              to='/logout'
              className='btn btn-light-primary font-weight-bold'
              id='header_logout-link'
            >
              <FormattedMessage id='AUTH.LOGOUT.BUTTON' />
            </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
